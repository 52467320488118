@import "./partials/typography.scss";
@import "./partials/nav.scss";
@import "./partials/effects.scss";
@import "./partials/button.scss";
@import "./partials/popup.scss";
@import "./partials/alert.scss";


.grid-container {
	padding: 146px calc(max((100% - 1088px) / 2, 18px)) 0;
}