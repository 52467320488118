.button, button {
	text-decoration: none;
	display: inline-block;
	padding: 13px 48px;
	line-height: 24px;
	border-radius: 26px;
	text-align: center;
	white-space: nowrap;
	border: none;
	cursor: pointer;
	transition: background-color .2s, color .2s;

	&:focus {
		outline: none;
	}

	&.solid {
		background-color: #027B9B;
		color: #fff;
		font-weight: 500;
		font-size: 16px;

		&.green {
			background-color: #60AD32;

			&:hover {
				background-color: hsl(98, 55%, 20%);
			}
		}

		&.orange {
			background-color: #FC9215;

			&:hover {
				background-color: hsl(32, 97%, 25%);
			}
		}

		&.white {
			color: #027B9B;
			background-color: #fff;

			&:hover {
				background-color: #fff;
			}
		}

		&.secondary {
			font-weight: 400;
			color: #000;
			background-color: #EBEBEB;

			&:hover {
				background-color: #f5f5f5;
			}
		}

		&:hover {
			background-color: #004259;
		}
	}

	&.outline {
		padding: 11px 46px;
		border: 2px solid var(--button-color, #027B9B);
		font-weight: 500;
		color: var(--button-color, #027B9B);
		font-size: 16px;
		transition: background-color .2s, color .2s;

		&.green {
			--button-color: #60AD32;
		}

		&.orange {
			--button-color: #FC9215;
		}

		&.white {
			--button-color: #fff;
			--button-text: #027B9B;

			&:hover {
				background-color: #fff;
			}
		}

		&:hover, &:focus {
			color: var(--button-text, #fff);
			background-color: var(--button-color, #027B9B);
		}
	}

	&.compact {
		height: 38px;
		padding: 9px 24px;
		font-size: 14px;
		line-height: 20px;
	}
}

a.next {
	display: inline-flex;
	font-weight: 500;
	line-height: 1.5em;
	font-size: 16px;
	color: #027B9B;
	text-decoration: none;

	&:not(.always-arrow) {
		@media screen and (max-width: 900px) {
			text-decoration: underline;

			.arrow {
				display: none;
			}
		}
	}

	&:focus {
		outline: none;
	}

	&:focus, &:hover {
		.arrow::before {
			width: 65px;
		}
	}

	.arrow {
		display: inline-flex;
		margin-left: 18px;
		align-items: center;

		&::before {
			grid-column: 2;
			height: 2px;
			width: 50px;
			border-radius: 1px;
			background-color: currentColor;
			transition: width .2s;
			content: "";
		}

		&::after {
			grid-column: 3;
			height: 16px;
			width: 9px;
			transform: translate3d(-7px, 0, 0);
			background-color: currentColor;
			clip-path: polygon(0px 1.5px, 1.5px 0px, 9px 8px, 1.5px 16px, 0px 14.5px, 6.5px 8px);
			content: "";
		}
	}
}