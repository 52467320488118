#popup {
	position: fixed;
	z-index: 20;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	opacity: 0;
	overflow-y: auto;
	overflow-x: hidden;
	transition: opacity .8s;

	&.open {
		pointer-events: all;
		opacity: 1;

		.box, .close {
			transform: translateY(0px);
		}
	}

	&::-webkit-scrollbar {
		display: none;
	}

	.background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
	}

	.box {
		padding: 88px 92px 70px 92px;
		margin: 144px auto 100px auto;
		width: 1088px;
		box-sizing: border-box;
		max-width: 100%;
		background-color: #fff;
		transform: translateY(10vh);
		transition: transform .8s;

		@media screen and (max-width: 1088px) {
			margin: 54px auto 0px auto;
		}

		@media screen and (max-width: 950px) {
			padding: 88px 20px 50px 20px;
		}

		.announcement--locales {
			display: flex;
			justify-content: center;
			margin-bottom: 24px;

			& > a {
				margin: 0 12px;
			}
		}

		.announcement--content {
			display: none;

			&.active {
				display: block;
			}

			.text-center, &.text-center {
				text-align: center;
			}
		}
	}

	.close {
		position: absolute;
		top: 176px;
		right: calc(50% - (#{m}in(100%, 1088px) / 2) + 34px);
		width: 24px;
		height: 24px;
		cursor: pointer;
		display: block;
		transform: translateY(10vh);
		transition: transform .8s;

		@media screen and (max-width: 1088px) {
			top: 89px;
		}

		&::before, &::after {
			position: absolute;
			background-color: #000;
			transform-origin: center;
			width: 24px;
			height: 2px;
			border-radius: 1px;
			content: "";
		}

		&::before {
			transform: translate(0, 12px) rotate(45deg);
		}

		&::after {
			transform: translate(0, 12px) rotate(-45deg);
		}
	}

	.text-center {
		text-align: center;
	}

	video {
		width: 100%;
		height: auto;
	}
}
