#header {
	position: fixed;
	display: grid;
	top: 0;
	left: 0;
	right: 0;
	--padding-top: 72px;
	--padding-bottom: 36px;
	padding: var(--padding-top) calc(#{m}ax((100vw - 1088px) / 2, 18px)) var(--padding-bottom) calc(#{m}ax((100vw - 1088px) / 2, 18px));
	height: 38px;
	box-sizing: content-box;
	align-items: center;
	z-index: 10;
	transition: height .5s, padding-top .3s, padding-bottom .3s;
	grid-template-columns: 1fr max-content max-content;
	grid-template-rows: 38px auto;
	grid-template-areas:
		"a d b"
		"c c c";

	@media screen and (max-width: 770px) {
		--padding-top: 36px;
		--padding-bottom: 36px;
	}

	& ~ section, & ~ footer, & ~ .element {
		transition: transform .5s;
	}

	a {
		text-decoration: none;
	}

	&.open {
		padding-bottom: 62px;
		height: calc(100% - var(--padding-top) - var(--padding-bottom));

		& ~ section, & ~ footer, & ~ .element {
			transform: translateY(50vh);
		}

		&::after {
			height: 100%;
		}

		.menu {
			padding-top: 156px;
			opacity: 1;

			@media screen and (max-width: 924px) {
				padding-top: 145px;
			}
		}

		.buttons {
			opacity: 1;
			transition: opacity 0.3s .35s;
		}

		.menu-toggle span {
			&:nth-child(1) {
				transform: translate(0px, 9px) rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translate(0px, 9px) rotate(-45deg);
			}
		}
	}

	&:not(.open) {
		&.solid {
			--padding-top: 8px;
			--padding-bottom: 8px;
		}

		&.light, &.solid {
			.buttons .sign-in {
				color: #969696;
			}

			.logo {
				color: #005573;
			}

			.page-locales a {
				color: #027B9B;
			}

			.menu-toggle span {
				background-color: #027B9B;
			}
		}
	}

	&.solid::before {
		backdrop-filter: blur(10px);
		background: rgba(255, 255, 255, 0.9);
	}

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(38px + var(--padding-top) + var(--padding-bottom));
		transition: background-color .3s, height .3s;
		content: "";
	}

	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0%;
		background: linear-gradient(to bottom, #004259 0vh, #2391AF 100vh);
		transition: height .5s;
		content: "";
	}

	.logo {
		display: inline-block;
		position: relative;
		z-index: 2;
		grid-area: a;
		justify-self: flex-start;
		height: 30px;
		width: auto;
		margin-right: auto;
		color: #fff;
		transition: color .2s;

		svg {
			width: auto;
			height: 100%;
		}
	}

	.page-locales {
		grid-area: d;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 2;
		margin-right: 40px;

		@media screen and (max-width: 600px) {
			display: none;
		}
		
		a {
			margin-left: 28px;
			font-size: 20px;
			color: rgba(255,255,255,0.5);
			transition: color .2s;

			&:hover {
				color: rgba(255,255,255,0.75);
			}
		}
	}

	.menu-toggle {
		grid-area: b;
		justify-self: flex-end;
		display: inline-flex;
		position: relative;
		z-index: 2;
		margin-left: auto;
		width: 27px;
		height: 18px;

		span {
			position: absolute;
			display: inline-block;
			width: 27px;
			height: 2px;
			border-radius: 1px;
			background-color: #fff;
			transform-origin: center;
			transition: transform .3s, opacity .3s, background-color .3s;

			&:nth-child(1) {
				transform: translate(0px, 0px);
			}

			&:nth-child(2) {
				transform: translate(0px, 9px);
			}

			&:nth-child(3) {
				transform: translate(0px, 18px);
			}
		}
	}

	.menu {
		grid-area: c;
		display: grid;
		grid-template-columns: max-content max-content auto;
		position: relative;
		z-index: 1;
		height: 100%;
		align-self: center;
		width: 100%;
		margin: 0 auto;
		opacity: 0;
		overflow-y: auto;
		transition: padding-top .5s, opacity .3s;

		@media screen and (max-width: 924px) {
			grid-template-columns: 1fr;
			grid-template-rows: auto auto;
			align-items: center;
			height: calc(100% - 38px);
		}

		&::-webkit-scrollbar {
			display: none;
		}

		nav.primary {
			margin-right: 60px;
			width: max-content;

			@media screen and (max-width: 924px) {
				margin-right: 0;
				width: 100%;
				margin-bottom: 66px;
			}
			
			a {
				display: block;
				width: max-content;
				margin-right: auto;
				font-size: 41px;
				color: rgba(255, 255, 255, 0.5);
				transition: color .3s;

				@media screen and (max-width: 924px) {
					font-size: 36px;
					line-height: 53px;
				}

				&.current {
					color: #fff;
				}

				&:hover {
					color: rgba(255, 255, 255, 0.75);
					transition: color .3s;
				}
			}
		}

		nav.secondary {
			margin-top: 12px;
			width: max-content;

			@media screen and (max-width: 924px) {
				margin-bottom: 72px;
			}

			a {
				display: block;
				width: max-content;
				margin-right: auto;
				font-size: 20px;
				margin-bottom: 15px;
				color: rgba(255, 255, 255, 0.5);
				transition: color .3s;

				&.current {
					color: #fff;
				}

				&:hover {
					color: rgba(255, 255, 255, 0.75);
					transition: color .3s;
				}
			}
		}

		.links {
			margin-left: auto;

			@media screen and (max-width: 924px) {
				margin-left: 0;
				width: 100%;
				margin-top: auto;
			}

			.sign-in {
				display: block;
				width: max-content;
				color: rgba(255, 255, 255, 0.5);
				font-size: 20px;
				margin-bottom: 22px;
				transition: color .2s;

				@media screen and (max-width: 924px) {
					color: #fff;
					text-decoration: underline;
				}

				&:hover {
					color: rgba(255, 255, 255, 0.75);
				}
			}

			.get-connected {
				color: #fff;
				font-size: 40px;
				margin-bottom: 20px;
				padding-right: 15px;
				transition: padding-right .2s;

				@media screen and (max-width: 924px) {
					display: flex;
					margin-right: auto;
					font-size: 36px;
					line-height: 53px;
				}

				&:hover, &:focus {
					padding-right: 0px;
				}
			}

			.locales {
				margin-bottom: 50px;

				a {
					margin-right: 25px;
					font-size: 20px;
					color: rgba(255, 255, 255, 0.5);
					transition: color .2s;
					
					&:hover {
						color: rgba(255, 255, 255, 0.75);
					}
				}
			}

			.social {
				display: flex;
				width: max-content;
				color: #fff;
				line-height: 21px;

				@media screen and (max-width: 924px) {
					display: inline-block;
					margin-right: 24px;
					margin-bottom: 100px;
				}

				&:hover span {
					text-decoration: underline;
				}

				&:not(:last-child) {
					margin-bottom: 22px;
				}

				i {
					width: 24px;
					text-align: center;
					font-size: 21px;
					margin-right: 6px;

					@media screen and (max-width: 924px) {
						font-size: 32px;
						margin-right: 0;
					}
				}

				span {
					font-size: 17px;
					line-height: 21px;

					@media screen and (max-width: 924px) {
						display: none;
					}
				}
			}
		}
	}
}

#footer {
	display: grid;
	padding: 65px #{m}ax(18px, calc((100% - 1094px) / 2));
	background-color: #fff;
	justify-content: space-between;
	column-gap: 16px;
	row-gap: 100px;
	grid-template-areas:
		"a b c d e"
		"f f f . g";

	@media screen and (max-width: 860px) {
		row-gap: 32px;
		grid-template-areas:
			"a b c"
			". d e"
			"f f f"
			". g g";
	}

	@media screen and (max-width: 600px) {
		grid-template-columns: 100%;
		grid-template-areas:
			"a"
			"e"
			"e"
			"b"
			"c"
			"d"
			"f"
			"g";
	}

	.logo {
		grid-area: a;
		display: inline-block;
		margin-right: auto;
		height: 28px;
		
		img {
			height: 100%;
		}
	}

	nav a, .social a {
		text-decoration: none;

		&:hover, &:focus {
			outline: none;
			color: #43aac4;

			i {
				color: #43aac4;
			}
		}
	}

	nav, .social {
		display: flex;
		flex-direction: column;

		a {
			display: inline-flex;
			align-items: center;
			color: #6A6A6A;
			font-size: 14px;
			margin-right: auto;

			&.current {
				font-weight: 500;
				color: #027b9b;
			}

			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}

		i {
			color: #969696;
			font-size: 13px;
			margin-right: 12px;
		}

		&.social {
			grid-area: d;
		}

		&.primary {
			grid-area: b;

			a {
				font-size: 16px;
			}
		}

		&.secondary {
			grid-area: c;
		}
	}

	.social {
		@media screen and (max-width: 600px) {
			flex-direction: row;

			a {
				margin-right: 27px;
				margin-bottom: 0 !important;

				i {
					font-size: 30px;
				}

				span {
					display: none;
				}
			}
		}
	}

	.buttons {
		grid-area: e;
		display: flex;
		flex-direction: column;

		.button:not(:last-child) {
			margin-bottom: 12px;
		}
	}

	.copyright {
		grid-area: f;
		font-size: 12px;
		color: #6A6A6A;
	}

	.prisma {
		grid-area: g;
		font-size: 12px;
		color: #6A6A6A;
		text-align: right;

		@media screen and (max-width: 600px) {
			text-align: left;
		}

		a {
			text-decoration: underline;
			color: inherit;
		}
	}
}
