.alert-bar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: #333;
    width: 100%;
    font-size: 15px;
    color: #fff;
    text-align: center;
    padding:10px;
    z-index: 30;
    box-sizing: border-box;

    p {
        margin:0px;
    }
}