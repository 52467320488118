$fx-appear-time: 0.6s;
$fx-appear-delay: 0.3s;
$fx-appear-distance: 100px;

[fx*=parallax] {
	transform: translateY(calc(var(--parallax) * var(--range)));
}

[fx*=appear][fx*=ready] {
	[fx*=lr], [fx*=rl], [fx*=tb], [fx*=bt] {
		transform: translate(0%, 0%);
		opacity: 1;
	}

	[fx*=fade] {
		opacity: 1;
	}
}

[fx*=appear] {
	[fx*=lr], [fx*=rl], [fx*=tb], [fx*=bt] {
		transition: transform $fx-appear-time calc(var(--index, 0) * #{$fx-appear-delay}), opacity linear $fx-appear-time calc(var(--index, 0) * #{$fx-appear-delay});
		opacity: 0;
	}

	[fx*=lr] {
		transform: translateX(-$fx-appear-distance);
	}

	[fx*=rl] {
		transform: translateX($fx-appear-distance);
	}

	[fx*=tb] {
		transform: translateY(-$fx-appear-distance);
	}

	[fx*=bt] {
		transform: translateY($fx-appear-distance);
	}

	[fx*=fade] {
		transition: opacity $fx-appear-time linear calc(var(--index, 0) * #{$fx-appear-delay});
		opacity: 0;
	}
}