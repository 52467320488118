body {
	margin: 0;
	font-family: heebo, sans-serif;
}

@mixin default-fonts {
	p {
		margin: 1.2em 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.button {
			margin-top: 8px;
			margin-bottom: 8px;
			margin-inline: 15px;
		}
	}

	h1 {
		font-size: 56px;
		line-height: 72px;
	
		@media screen and (max-width: 500px) {
			font-size: 48px;
			line-height: 52px;
		}
	}

	h2 {
		font-size: 40px;
		line-height: 50px;
	}
	
	h3 {
		font-size: 28px;
		line-height: 25px;
	}

	h4 {
		font-size: 24px;
		line-height: 28px;
	}

	h6 {
		font-weight: 400;
		color: var(--secondary-font-color, #808080);
		font-size: 16px;
		margin: 0;
	}

	h1, h2, h3, h4, h5 {
		font-weight: 500;
		margin: 0.5em 0;
	}

	a {
		color: #027B9B;
		text-decoration: none;
	}

	b, strong {
		font-weight: 500;
	}

	hr {
		display: block;
		width: 100%;
		height: 1px;
		border: none;
		background-color: #C3C3C3;
		margin: 26px 0 35px 0;
	}
}

@font-face {
	font-family: "heebo";
	font-weight: 300;
	src: url('/_resources/themes/hark/fonts/Heebo-Light.ttf') format('truetype');
}

@font-face {
	font-family: "heebo";
	font-weight: normal;
	src: url('/_resources/themes/hark/fonts/Heebo-Regular.ttf') format('truetype');
}

@font-face {
	font-family: "heebo";
	font-weight: 500;
	src: url('/_resources/themes/hark/fonts/Heebo-Medium.ttf') format('truetype');
}

@font-face {
	font-family: "heebo";
	font-weight: 600;
	src: url('/_resources/themes/hark/fonts/Heebo-Bold.ttf') format('truetype');
}
